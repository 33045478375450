import React from "react";
import "./main.scss";

class Subscribe extends React.Component {
    render() {
        /**
         * There is also "fr" version, need to manage when the i18n feature will be enabled
         */
        return (
            <main className="_main">
                <iframe
                    title="Subscription"
                    width="100%"
                    height="100%"
                    src="https://b7cc9571.sibforms.com/serve/MUIEACX1fAewQPX6BTGzbyXSSv2fSxnKVtIVcFELJsUUtnhxNKr3jDIsLYE4GOn0Jnc_8EN99FlQzJl4YtRvCEeF2Ui62OM6kry5IqQjzgmffmyZQc0Yn5eTrq9V_HIKP-ObdfdmKNWZpOeztnzy-u9Sc6tI4JGTK0kmI_cwtpHYvyKYyJSrYOfNyNAwOr9b9oXZq2zEkReHP56f"
                    frameBorder="0"
                    scrolling="auto"
                    allowFullScreen
                ></iframe>
            </main>
        );
    }
}

export default Subscribe;
