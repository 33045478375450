import React from "react";

import { Link } from "react-router-dom";
import "./main.scss";

class SubscribtionPopup extends React.Component {
    render() {
        return (
            <div className="_subscribe">
                <Link to="/subscribe">Click here and subscribe to be aware of my availability.</Link>
            </div>
        );
    }
}

export default SubscribtionPopup;
