import React, { useEffect } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// import FrenchFlagParticles from "./component/FrenchFlagParticles/main";
import Navigation from "component/Navigation/main";
import Home from "page/Home/main";
// import CV from "page/CV/main";
import Contact from "page/Contact/main";
import Subscribe from "page/Subscribe/main";
import ErrorNotFound from "page/ErrorNotFound/main";

import "./App.scss";

const App = () => {
    const location = useLocation();

    useEffect(() => {
        let className = location.pathname;
        className = className.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "");

        if (!className.length) {
            className = "home";
        }

        className = `page-${className}`;
        document.documentElement.setAttribute("class", className);
    }, [location]);

    return (
        <>
            <Navigation />

            <Routes>
                <Route path="/">
                    <Route index element={<Home />} />

                    {/* <Route path="cv" element={<CV />} /> */}

                    <Route path="contact" element={<Contact />} />

                    <Route path="subscribe" element={<Subscribe />} />

                    <Route path="404" element={<ErrorNotFound />} />

                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Route>
            </Routes>
        </>
    );
};

export default App;
