import React from "react";

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { RoundedBoxGeometry } from "three/examples/jsm/geometries/RoundedBoxGeometry.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import Stats from "three/examples/jsm/libs/stats.module.js";

const homeThreeJS = {
    debugMode: false,
    isAlreadyLoaded: false,
    isHolding: false,
    stats1: null,
    stats2: null,
    raycaster: null,
    pointer: null,
    intersects: null,
    container: null,
    camera: null,
    scene: null,
    renderer: null,
    loadingManager: null,
    modelFile: "./models/me.glb",
    modelPrototypeFile: "./models/me.stl",
    fontFile: "./fonts/calibri-regular.json",
    resetButtonFile: "./images/reset.png",
    modelObject: null,
    roundedBoxObject: null,
    clickedObject: null,
    previousTouch: 0,
    resetButton: null,
    availability: {
        isAvailable: true,
        availableText: "Available to work",
        availableColor: 0x00ff00,
        availableTextColor: 0x00ff00,
        notAvailableText: "Not available to work",
        notAvailableColor: 0xcc0000,
        notAvailableTextColor: 0x2c3e50,
        groupObject: null,
    },
};

class Model extends React.Component {
    constructor() {
        super();
        this.eventBinds = {};
        // this.eventBinds.load = this.onWindowLoad.bind(this);
        this.eventBinds.resize = this.onWindowResize.bind(this);
        this.eventBinds.keydown = this.onKeyDown.bind(this);
        this.eventBinds.mousemove = this.onMouseMove.bind(this);
        this.eventBinds.mousedown = this.onMouseDown.bind(this);
        this.eventBinds.mouseup = this.onMouseUp.bind(this);
        this.requestAnimationFrameVar = "";
    }

    async componentDidMount() {
        this.initEvents();
        await this.init();

        this.makeResponsive();
    }

    componentWillUnmount() {
        this.killEvents();
    }

    render() {
        return <div className="_model"></div>;
    }

    async init() {
        if (!homeThreeJS.isAlreadyLoaded) {
            homeThreeJS.raycaster = new THREE.Raycaster();
            homeThreeJS.pointer = new THREE.Vector3();
            homeThreeJS.scene = new THREE.Scene();
            homeThreeJS.scene.background = new THREE.Color("#000");

            this.addCamera();
            this.addLights();
            this.addRenderer();
            this.addLoadingManager();
            // await this.addModelPrototype();
            await this.addModel();
            await this.addAvailability();
            await this.addPositionResetButton();

            homeThreeJS.isAlreadyLoaded = true;
        }

        homeThreeJS.container = document.querySelector("._model");
        homeThreeJS.container.appendChild(homeThreeJS.renderer.domElement);
        this.animate();
        this.setLoadingDone();
    }

    enableDebugMode() {
        const axesHelper = new THREE.AxesHelper(5);
        const modelHelper = new THREE.BoxHelper(homeThreeJS.modelObject, 0xffff00);
        const groupHelper = new THREE.BoxHelper(homeThreeJS.availability.groupObject, 0xffff00);

        homeThreeJS.scene.add(axesHelper);
        homeThreeJS.scene.add(modelHelper);
        homeThreeJS.scene.add(groupHelper);

        homeThreeJS.stats1 = new Stats();
        homeThreeJS.stats1.showPanel(0); // fps
        homeThreeJS.stats1.domElement.style.cssText = "position:absolute;top:0px;left:0px;";
        document.body.appendChild(homeThreeJS.stats1.dom);

        homeThreeJS.stats2 = new Stats();
        homeThreeJS.stats2.showPanel(1); // ms
        homeThreeJS.stats2.domElement.style.cssText = "position:absolute;top:0px;left:80px;";
        document.body.appendChild(homeThreeJS.stats2.dom);
    }

    addCamera() {
        homeThreeJS.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.01, 1000);
        homeThreeJS.camera.position.set(0, 0.1, 1);
        homeThreeJS.camera.zoom = 3;
    }

    addLights() {
        const ambientLight = new THREE.AmbientLight(0x404040, 1);
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(0, 1, 0);
        directionalLight.castShadow = false;
        homeThreeJS.scene.add(directionalLight);
        homeThreeJS.scene.add(ambientLight);
    }

    addRenderer() {
        homeThreeJS.renderer = new THREE.WebGLRenderer({ antialias: true });
        homeThreeJS.renderer.outputEncoding = THREE.sRGBEncoding;
        homeThreeJS.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        homeThreeJS.renderer.outputEncoding = THREE.sRGBEncoding;
    }

    addLoadingManager() {
        let progressBar = document.querySelector("._progressbar span");
        homeThreeJS.loadingManager = new THREE.LoadingManager();

        homeThreeJS.loadingManager.onProgress = function (item, loaded, total) {
            let loadedCount = Math.round((loaded / total) * 100, 2);
            let loadedPercent = loadedCount + "%";
            progressBar.style.width = loadedPercent;
        };

        homeThreeJS.loadingManager.onError = function (url) {
            console.log("Error loading");
            progressBar.style.backgroundColor = "red";
            progressBar.innerText = "Error";
        };
    }

    async addModel() {
        const gltfLoader = new GLTFLoader(homeThreeJS.loadingManager);
        gltfLoader.crossOrigin = "anonymous";
        const gltf = await gltfLoader.loadAsync(homeThreeJS.modelFile);

        const newModel = gltf.scene.children[0];
        newModel.name = newModel.userData.name = "Model";
        newModel.userData.autoRotate = true;
        newModel.scale.set(0.11, 0.11, 0.1);

        // replace by the new one
        homeThreeJS.scene.add(newModel);

        // // remove prototype
        homeThreeJS.scene.remove(homeThreeJS.modelObject);

        homeThreeJS.modelObject = newModel;
    }

    async addModelPrototype() {
        const stlLoader = new STLLoader(homeThreeJS.loadingManager);
        stlLoader.crossOrigin = "anonymous";
        const stl = await stlLoader.loadAsync(homeThreeJS.modelPrototypeFile);
        const material = new THREE.MeshPhysicalMaterial();
        const stlObj = new THREE.Mesh(stl, material);

        homeThreeJS.modelObject = stlObj;

        homeThreeJS.modelObject.name = homeThreeJS.modelObject.userData.name = "Model";
        homeThreeJS.modelObject.userData.autoRotate = false;
        homeThreeJS.modelObject.position.set(0, 0.102, 0);
        homeThreeJS.modelObject.scale.set(0.11, 0.11, 0.1);

        homeThreeJS.scene.add(homeThreeJS.modelObject);

        stl.dispose();
        material.dispose();
    }

    async addAvailability() {
        const status = this.addAvailabilityStatus();
        const text = await this.addAvailabilityText();
        homeThreeJS.availability.groupObject = new THREE.Group();
        homeThreeJS.availability.groupObject.add(status);
        homeThreeJS.availability.groupObject.add(text);

        homeThreeJS.scene.add(homeThreeJS.availability.groupObject);
    }

    addAvailabilityStatus() {
        const roundedBoxGeometry = new RoundedBoxGeometry(0.005, 0.005, 0.005, 6, 2);

        const roundedBoxMaterial = new THREE.MeshBasicMaterial({
            color: this.getAvailabilityItem("color"),
        });

        const roundedBox = new THREE.Mesh(roundedBoxGeometry, roundedBoxMaterial);
        roundedBox.name = "Availability status";

        roundedBoxGeometry.dispose();
        roundedBoxMaterial.dispose();

        homeThreeJS.roundedBoxObject = roundedBox;

        return roundedBox;
    }

    async addAvailabilityText() {
        const fontLoader = new FontLoader(homeThreeJS.loadingManager);

        const result = await fontLoader.loadAsync(homeThreeJS.fontFile);

        const textGeometry = new TextGeometry(this.getAvailabilityItem("text"), {
            font: result,
            size: 0.005,
            height: 0.0025,
        });

        const fontMaterial = new THREE.MeshBasicMaterial({
            color: this.getAvailabilityItem("textColor"),
            transparent: true,
            opacity: 0.8,
            side: THREE.DoubleSide,
        });

        const availabilityText = new THREE.Mesh(textGeometry, fontMaterial);

        availabilityText.position.y =
            homeThreeJS.roundedBoxObject.position.y - textGeometry.parameters.options.size / 2;
        availabilityText.position.x =
            homeThreeJS.roundedBoxObject.position.x + (textGeometry.parameters.options.size / 2) * 2;
        availabilityText.position.z = homeThreeJS.roundedBoxObject.position.z;
        availabilityText.name = "Availability text";

        textGeometry.dispose();
        fontMaterial.dispose();

        return availabilityText;
    }

    async addPositionResetButton() {
        let geometry = new THREE.CylinderBufferGeometry(0.005, 0.005, 0.00125, 32);
        const textureLoader = new THREE.TextureLoader(homeThreeJS.loadingManager);
        let texture = await textureLoader.loadAsync(homeThreeJS.resetButtonFile);
        let textureMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, map: texture });

        let materials = [textureMaterial, textureMaterial, textureMaterial];

        homeThreeJS.resetButton = new THREE.Mesh(geometry, materials);
        homeThreeJS.resetButton.rotation.x = Math.PI / 2;
        homeThreeJS.resetButton.rotation.y = Math.PI / 2;
        homeThreeJS.resetButton.position.x = 0.04;
        homeThreeJS.resetButton.position.y = homeThreeJS.resetButton.geometry.parameters.radiusTop + 0.001;
        homeThreeJS.resetButton.name = "Reset";
        homeThreeJS.resetButton.visible = false;

        geometry.dispose();
        texture.dispose();
        textureMaterial.dispose();

        homeThreeJS.scene.add(homeThreeJS.resetButton);
    }

    updateHTMLSubscribePopupPosition() {
        const availabilityGroupPosition = this.toScreenPosition(homeThreeJS.availability.groupObject);
        const subscribePopup = document.querySelector("._subscribe");

        if (window.innerWidth < 800) {
            subscribePopup.style.left = subscribePopup.style.top = undefined;

            subscribePopup.classList.remove("_bottom");
            subscribePopup.classList.add("_center");
            subscribePopup.classList.add("_top");
        } else {
            subscribePopup.style.left = `${availabilityGroupPosition.x - 20}px`;
            // 50 is the height of the navbar
            subscribePopup.style.top = `${availabilityGroupPosition.y + 50 + 20}px`;

            subscribePopup.classList.remove("_center");
            subscribePopup.classList.remove("_top");
            subscribePopup.classList.add("_bottom");
        }

        subscribePopup.classList.add("_show");
    }

    /**
     * TODO: need to update this function because it does not returns the start point of the object.
     * You can activate the debug mode and see it
     */
    toScreenPosition(object) {
        const renderer = homeThreeJS.renderer;
        const vector = new THREE.Vector3();
        const canvas = renderer.getContext().canvas;
        const camera = homeThreeJS.camera;

        object.updateMatrixWorld();
        vector.setFromMatrixPosition(object.matrixWorld);
        vector.project(camera);

        return {
            x: Math.round((0.5 + vector.x / 2) * (canvas.width / window.devicePixelRatio)),
            y: Math.round((0.5 - vector.y / 2) * (canvas.height / window.devicePixelRatio)),
        };
    }

    setLoadingDone() {
        let main = document.querySelector("main._home");
        main.classList.add("_loading_done");
    }

    initEvents() {
        // window.addEventListener("load", this.eventBinds.load);
        window.addEventListener("resize", this.eventBinds.resize);
        window.addEventListener("keydown", this.eventBinds.keydown);
        window.addEventListener("mousemove", this.eventBinds.mousemove);
        window.addEventListener("touchmove", this.eventBinds.mousemove);
        window.addEventListener("mousedown", this.eventBinds.mousedown);
        window.addEventListener("touchstart", this.eventBinds.mousedown);
        window.addEventListener("mouseup", this.eventBinds.mouseup);
        window.addEventListener("touchend", this.eventBinds.mouseup);
    }

    killEvents() {
        cancelAnimationFrame(this.requestAnimationFrameVar);
        // window.removeEventListener("load", this.eventBinds.load);
        window.removeEventListener("resize", this.eventBinds.resize);
        window.removeEventListener("keydown", this.eventBinds.keydown);
        window.removeEventListener("mousemove", this.eventBinds.mousemove);
        window.removeEventListener("touchmove", this.eventBinds.mousemove);
        window.removeEventListener("mousedown", this.eventBinds.mousedown);
        window.removeEventListener("touchstart", this.eventBinds.mousedown);
        window.removeEventListener("mouseup", this.eventBinds.mouseup);
        window.removeEventListener("touchend", this.eventBinds.mouseup);
    }

    mouseEventProcess(event) {
        const rect = homeThreeJS.renderer.domElement.getBoundingClientRect();
        /* Mobile support */
        let clientX = event.clientX ?? event.touches[0].clientX;
        let clientY = event.clientY ?? event.touches[0].clientY;
        /* Mobile support */
        homeThreeJS.pointer.x = ((clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
        homeThreeJS.pointer.y = -((clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

        // update the picking ray with the camera and pointer position
        homeThreeJS.raycaster.setFromCamera(homeThreeJS.pointer, homeThreeJS.camera);

        // calculate objects intersecting the picking ray
        homeThreeJS.intersects = homeThreeJS.raycaster.intersectObjects(homeThreeJS.scene.children, true);
    }

    async makeResponsive() {
        if (window.innerWidth < 800) {
            this.centerHorizontally(homeThreeJS.availability.groupObject);
            homeThreeJS.availability.groupObject.position.y = 0.213;
        } else {
            homeThreeJS.availability.groupObject.position.x = 0.04;
            homeThreeJS.availability.groupObject.position.y = 0.2;
        }

        // Set the camera's aspect ratio
        homeThreeJS.camera.aspect = window.innerWidth / window.innerHeight;
        // update the camera's frustum
        homeThreeJS.camera.updateProjectionMatrix();
        // update the size of the renderer AND the canvas
        homeThreeJS.renderer.setSize(window.innerWidth, window.innerHeight);
        // set the pixel ratio (for mobile devices)
        homeThreeJS.renderer.setPixelRatio(window.devicePixelRatio);

        if (homeThreeJS.debugMode === true) {
            this.enableDebugMode();
        }

        this.updateHTMLSubscribePopupPosition();
    }

    changeCursor(type) {
        document.querySelector("html").style.cursor = type;
    }

    centerHorizontally(object) {
        // need to reset the value here to fix the bug when window is resized
        object.position.x = 0;
        const box3 = new THREE.Box3().setFromObject(object);
        const vector = new THREE.Vector3();
        box3.getCenter(vector);
        object.position.set(-vector.x, -vector.y, -vector.z);
    }

    /**
     * Detect "R" keypress to reset the position of camera
     */
    onKeyDown(event) {
        if (event.code === "KeyR") {
            this.resetPositions();
        }
    }

    onWindowLoad() {
        this.addModel();
    }

    onWindowResize() {
        this.makeResponsive();
    }

    onMouseMove(event) {
        this.mouseEventProcess(event);

        /* Mobile support */
        let touch = null;
        let movementX = event.movementX ?? 0;
        let movementSpeed = 0.005;

        if (event.touches && event.touches[0]) {
            touch = event.touches[0];
        }

        if (homeThreeJS.previousTouch) {
            // be aware that these only store the movement of the first touch in the touches array
            movementX = touch.clientX - homeThreeJS.previousTouch.clientX;
            movementSpeed = 0.01;
        }
        /* Mobile support */

        if (
            homeThreeJS.clickedObject === homeThreeJS.scene.getObjectByName("Model") ||
            homeThreeJS.clickedObject?.parent === homeThreeJS.scene.getObjectByName("Model")
        ) {
            if (homeThreeJS.isHolding === true) {
                homeThreeJS.modelObject.userData.autoRotate = false;
                homeThreeJS.modelObject.rotation.y += movementX * movementSpeed;
                homeThreeJS.resetButton.visible = true;
            }
        }

        if (homeThreeJS.intersects.length > 0) {
            let hovered = homeThreeJS.intersects[0].object;

            if (hovered.parent && hovered.parent === homeThreeJS.scene.getObjectByName("Model")) {
                hovered = homeThreeJS.scene.getObjectByName("Model");
            }

            if (hovered.name && hovered.visible === true) {
                switch (hovered.name) {
                    case "Reset":
                        this.changeCursor("pointer");
                        break;

                    case "Model":
                        this.changeCursor("grab");
                        break;

                    default:
                        this.changeCursor("default");
                        break;
                }
            }
        } else {
            this.changeCursor("default");
        }

        /* Mobile support */
        if (touch) {
            homeThreeJS.previousTouch = touch;
        }
        /* Mobile support */
    }

    onMouseDown(event) {
        this.mouseEventProcess(event);

        if (homeThreeJS.intersects.length > 0) {
            let selected = homeThreeJS.intersects[0].object;

            homeThreeJS.clickedObject = selected;

            if (selected.name && selected.visible === true && selected.name === "Reset") {
                this.resetPositions();
                this.changeCursor("default");
            }
        } else {
            homeThreeJS.clickedObject = null;
        }

        homeThreeJS.isHolding = true;
    }

    onMouseUp() {
        homeThreeJS.isHolding = false;
        /* Mobile support */
        homeThreeJS.previousTouch = null;
        /* Mobile support */
        homeThreeJS.modelObject.userData.autoRotate = true;
    }

    resetPositions() {
        homeThreeJS.modelObject.rotation.y = 0;
        homeThreeJS.resetButton.visible = false;
    }

    animate() {
        this.requestAnimationFrameVar = requestAnimationFrame(() => this.animate());

        if (homeThreeJS.modelObject && homeThreeJS.modelObject.userData.autoRotate === true) {
            homeThreeJS.modelObject.rotation.y += 0.002;
        }

        homeThreeJS.renderer.render(homeThreeJS.scene, homeThreeJS.camera);

        if (homeThreeJS.stats1 && homeThreeJS.stats2) {
            homeThreeJS.stats1.update();
            homeThreeJS.stats2.update();
        }
    }

    getAvailabilityItem(item) {
        const availability = homeThreeJS.availability;

        if (item === "color") {
            return availability.isAvailable ? availability.availableColor : availability.notAvailableColor;
        }

        if (item === "textColor") {
            return availability.isAvailable ? availability.availableTextColor : availability.notAvailableTextColor;
        }

        return availability.isAvailable ? availability.availableText : availability.notAvailableText;
    }
}

export default Model;
