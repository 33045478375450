import React from "react";

class ErrorNotFound extends React.Component {
    render() {
        return (
            <main className="_main">
                <p>404 not found</p>
            </main>
        );
    }
}

export default ErrorNotFound;
