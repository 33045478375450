import React from "react";

import { Link } from "react-router-dom";
import "./main.scss";

class Navigation extends React.Component {
    render() {
        return (
            <header>
                <nav>
                    <Link to="/">Home</Link>
                    {/* <Link to="/cv">CV</Link> */}
                    <Link to="/contact">Contact</Link>
                </nav>
            </header>
        );
    }
}

export default Navigation;
