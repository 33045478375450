import React from "react";
import "./main.scss";

class Contact extends React.Component {
    render() {
        return (
            <main className="_main">
                <p>
                    Linkedin:{" "}
                    <a href="https://www.linkedin.com/in/hsynv/">
                        https://www.linkedin.com/in/hsynv/
                    </a>
                </p>
            </main>
        );
    }
}

export default Contact;
