import React from "react";
import "./main.scss";

import Model from "../../component/Model/main";
import SubscribtionPopup from "../../component/SubscribtionPopup/main";

class Home extends React.Component {
    render() {
        return (
            <main className="_home">
                <Model />
                <SubscribtionPopup />
                <div className="_loading_area">
                    <div className="_progressbar">
                        <span></span>
                    </div>
                </div>
            </main>
        );
    }
}

export default Home;
